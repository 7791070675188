<template>
  <div
    v-show="!$route.meta.public"
    class="TheNavigationDrawer"
    :class="{
      'TheNavigationDrawer--expanded': (pinnedNav != null || activeNav != null) && navItemsWithSubMenu.length > 0,
    }"
  >
    <v-navigation-drawer
      app
      disable-resize-watcher
      disable-route-watcher
      permanent
      style="display: none"
      :width="pinnedNav == null ? 70 : 260"
    />

    <v-sheet
      width="70"
      min-width="70"
      max-width="70"
      color="darkBackground"
      class="TheNavigationDrawer__first-level"
    >
      <router-link
        v-ripple="{ class: 'white--text' }"
        to="/"
        class="TheNavigationDrawer__brand-link"
        @click="$route.name === 'Projects' && $router.go(0) /* refresh */"
      >
        <img
          :src="'/public/logotypes/logo-mini.svg'"
          alt="Apiary"
        >
      </router-link>
      <v-tooltip
        v-for="navItem in menu"
        :key="navItem.key"
        bottom
        :nudge-top="20"
        :disabled="!navsWithTooltip.includes(navItem.key)"
      >
        <template #activator="{ on, attrs }">
          <div
            v-show="navItemVisible(navItem)"
            v-bind="attrs"
            v-on="on"
          >
            <component
              :is="navItem.to ? 'router-link' : 'AppDiv'"
              v-ripple="navItem.to || navItem.click ? { class: 'white--text' } : false"
              :to="navItem.to"
              class="TheNavigationDrawer__nav-item"
              :class="{ 'TheNavigationDrawer__nav-item--active': getNavItemActive(navItem) }"
              tabindex="0"
              @mouseenter.native="activeNav = hoveredNav = navItem.key"
              @focus.native="activeNav = focusedNav = navItem.key"
              @mouseleave.native="onNavItemBlur"
              @blur.native="onNavItemBlur($event, 'focusedNav')"
              @click.native="navItem.active != null && (pinSecondLevel = true)"
            >
              <v-icon
                class="TheNavigationDrawer__nav-icon"
                color="inherit"
                v-text="navItem.icon"
              />
              <span
                ref="navItemLabels"
                class="TheNavigationDrawer__nav-label"
                :data-nav-key="navItem.key"
              >
                <span
                  class="text-truncate"
                  v-text="$t(navItem.name)"
                />
              </span>
            </component>
          </div>
        </template>

        <span v-text="$t(navItem.name)" />
      </v-tooltip>
    </v-sheet>

    <!--transition-group
      name="TheNavigationDrawer__second-level-transition"
      tag="div"
    -->
    <div class="TheNavigationDrawer__second-level-transition">
      <div
        v-for="navItem in navItemsWithSubMenu"
        v-show="navItemVisible(navItem) && (activeNav === navItem.key || pinnedNav === navItem.key)"
        :key="navItem.key"
        class="TheNavigationDrawer__second-level"
        :class="{
          'TheNavigationDrawer__second-level--pinned': pinnedNav === navItem.key
        }"
        @mouseenter="activeNav = hoveredNav = navItem.key"
        @focusin="activeNav = focusedNav = navItem.key"
        @mouseleave="onNavItemBlur"
        @focusout="onNavItemBlur($event, 'focusedNav')"
      >
        <TheNavigationDrawerProjectSelector
          v-if="navItem.key === NAV.PROJECTS"
          :project-id="projectId"
          class="mb-2"
        />
        <div
          v-else
          class="TheNavigationDrawer__heading"
        >
          {{ $t(navItem.menuName) }}
        </div>

        <v-list class="transparent">
          <template v-for="childItem in filterChildren(navItem.children)">
            <v-list-item
              :key="childItem.name + '--item'"
              class="TheNavigationDrawer__child-item"
              active-class="TheNavigationDrawer__child-item--active"
              exact-active-class="TheNavigationDrawer__child-item--active TheNavigationDrawer__child-item--exact-active"
              :class="{
                'TheNavigationDrawer__child-item--expanded': !!childItem.expanded
              }"
              v-bind="getChildBind(childItem)"
              v-on="getChildListeners(childItem, true)"
            >
              {{ $t(childItem.name) }}
              <v-icon
                v-if="childItem.children && childItem.children.length"
                class="TheNavigationDrawer__expand-icon"
                v-text="'mdi-menu-down'"
              />
            </v-list-item>

            <v-expand-transition :key="childItem.name + '--children'">
              <div v-if="childItem.expanded">
                <div class="TheNavigationDrawer__expansion-list">
                  <v-list-item
                    v-for="subChild in filterChildren(childItem.children)"
                    :key="subChild.name"
                    class="TheNavigationDrawer__child-item TheNavigationDrawer__child-item--sub"
                    active-class="TheNavigationDrawer__child-item--active"
                    exact-active-class="TheNavigationDrawer__child-item--active TheNavigationDrawer__child-item--exact-active"
                    v-bind="getChildBind(subChild)"
                    v-on="getChildListeners(subChild, true)"
                    @mousdown.stop
                  >
                    {{ $t(subChild.name) }}
                  </v-list-item>
                </div>
              </div>
            </v-expand-transition>
          </template>
        </v-list>

        <div
          v-show="$route.name !== 'Projects'"
          class="TheNavigationDrawer__pin"
        >
          <v-tooltip
            bottom
            nudge-top="12"
          >
            <template #activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :color="hover ? 'primary' : '#D3D6E2'"
                  icon
                  v-bind="attrs"
                  @click="pinSecondLevel = !pinSecondLevel"
                  v-on="on"
                >
                  <v-icon v-text="pinSecondLevel ? '$pin-pinned' : '$pin-unpinned'" />
                </v-btn>
              </v-hover>
            </template>
            <span v-text="pinSecondLevel ? $t('layout.UnpinSidebar') : $t('layout.PinSidebar')" />
          </v-tooltip>
        </div>
      </div>
    </div>
    <!--/transition-group-->

    <ReportDialog
      v-if="loadReportDialog && projectId != null"
      v-model="reportDialogModel"
      :project-id="projectId"
    />
  </div>
</template>

<script>
import * as R from 'ramda'

import { PROJECT_PERMISSION_LEVEL } from '../constants'

import Dialog from '../store/orm/dialog'
import Project from '../store/orm/project'

import TheNavigationDrawerProjectSelector from './TheNavigationDrawerProjectSelector'

const FADE_TIMEOUT = 180

const NAV = Object.freeze({
  CROSS_PROJECT_DASHBOARD: '_cross-project-dashboard',
  CROSS_PROJECT_ISSUES: '_cross-project-issues',
  PROJECTS: '_projects',
  ADMIN: '_admin',
})

const NAV_ITEMS = {
  [NAV.CROSS_PROJECT_DASHBOARD]: {
    name: 'layout.Dashboard',
    menuName: 'layout.Dashboard',
    icon: '$dashboard',
    key: NAV.CROSS_PROJECT_DASHBOARD,
    to: { name: 'Dashboard' },
    showIf: vm => vm.dashboardFeatureActive,
    active: vm => ['Dashboard', 'DashboardManage', 'EditCard'].includes(vm.$route.name),
    hideSecondLevel: true,
  },
  [NAV.CROSS_PROJECT_ISSUES]: {
    name: 'layout.Issues',
    menuName: 'layout.Issues',
    icon: 'mdi-bug-outline',
    key: NAV.CROSS_PROJECT_ISSUES,
    to: { name: 'IssueList' },
    showIf: vm => vm.dashboardFeatureActive,
    active: vm => ['IssueList', 'IssueListIssue', 'CardIssueList', 'CardIssueListIssue'].includes(vm.$route.name),
    hideSecondLevel: true,
  },
  [NAV.PROJECTS]: {
    name: 'layout.Projects',
    menuName: 'layout.Projects',
    icon: '$pentests',
    key: NAV.PROJECTS,
    to: { name: 'Projects' },
    active: vm => vm.$route.path.startsWith('/projects'),
    hideSecondLevel: vm => vm.projectId == null || vm.$route.name === 'Projects' || !vm.$route.path.startsWith('/projects'),
    children: [
      {
        name: 'layout.Dashboard',
        to: (vm) => ({
          name: 'ProjectDashboard',
          params: { projectId: vm.projectId },
        }),
      },
      {
        name: 'layout.Issues',
        to: (vm) => ({
          name: 'ProjectIssueList',
          params: { projectId: vm.projectId },
        }),
      },
      {
        name: 'layout.Checklists',
        to: (vm) => ({
          name: 'ChecklistsList',
          params: { projectId: vm.projectId },
        }),
      },
      {
        name: 'layout.Team',
        click: (vm) => vm.projectId && Dialog.open({
          componentName: 'TeamDialog',
          props: { projectId: vm.projectId },
        }),
      },
      {
        name: 'layout.DownloadReport',
        click: (vm) => { vm.loadReportDialog = vm.reportDialogModel = true },
      },
      {
        name: 'layout.Settings',
        showIf: (vm) =>
          vm.isAdmin ||
          [PROJECT_PERMISSION_LEVEL.OWNER.value]
            .includes(vm.project?.permission),
        children: Object.freeze([
          {
            name: 'layout.Sla',
            to: vm => ({
              name: 'ProjectSlaConfig',
              params: { projectId: vm.projectId },
            }),
          },
          {
            name: 'layout.Integrations',
            showIf: vm => vm.isAdmin,
            to: vm => ({
              name: 'Integration',
              params: { projectId: vm.projectId },
            }),
          },
        ]),
      },
    ],
  },
  [NAV.ADMIN]: {
    name: 'layout.Admin',
    menuName: 'layout.Administration',
    icon: 'mdi-cog-outline',
    key: NAV.ADMIN,
    to: { name: 'Admin' },
    showIf: vm => vm.isAdmin,
    active: vm => vm.$route.path.startsWith('/admin'),
    children: [
      {
        name: 'layout.License',
        to: { name: 'License' },
      },
      {
        name: 'layout.Users',
        to: { name: 'Users' },
      },
      {
        name: 'layout.DefaultStatuses',
        to: { name: 'GlobalIssueStatus' },
      },
      {
        name: 'layout.Sla',
        to: { name: 'GlobalSlaConfig' },
      },
      {
        name: 'layout.JiraServers',
        to: { name: 'IntegrationServers' },
      },
      {
        name: 'layout.Ldap',
        to: { name: 'LdapConfig' },
      },
      {
        name: 'layout.AppConnections',
        to: { name: 'AppConnections' },
      },
      {
        name: 'layout.Smtp',
        to: { name: 'SmtpConfig' },
      },
      {
        name: 'layout.ReportTemplates',
        to: { name: 'ReportTemplates' },
      },
    ],
  },
}

export default {
  name: 'TheNavigationDrawer',

  components: {
    ReportDialog: () => import(
      /* webpackChunkName: "report-dialog" */
      '../components/ReportDialog'),

    TheNavigationDrawerProjectSelector,
  },

  props: {
    projectId: { type: String, default: null },
  },

  data() {
    const menu = [
      NAV_ITEMS[NAV.CROSS_PROJECT_DASHBOARD],
      NAV_ITEMS[NAV.CROSS_PROJECT_ISSUES],
      NAV_ITEMS[NAV.PROJECTS],
      NAV_ITEMS[NAV.ADMIN],
    ]
    const activeNavItem = menu.find(item => item.active?.(this))

    return {
      NAV,

      menu,

      activeNav: null,
      hoveredNav: null,
      focusedNav: null,
      pinSecondLevel: activeNavItem != null,

      navsWithTooltip: [],

      loadReportDialog: false,
      reportDialogModel: false,

      saving: false,
    }
  },

  storage: {
    keys: ['pinSecondLevel'],
    namespace: 'components/TheNavigationDrawer',
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
    isAdmin() { return this.currentUser?.isAdmin },

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },
    dashboardFeatureActive() {
      return this.$store.getters['license/featureActive']('crossProjectDashboard')
    },

    navItemsWithSubMenu() {
      return this.menu
        .filter(this.navItemVisible)
        .filter(navItem => !this.resolveDeclarationFn(navItem.hideSecondLevel))
    },

    pinnedNav() {
      const { pinSecondLevel, menu } = this
      if (!pinSecondLevel) return null
      const navItem = menu.find(({ active }) => active?.(this))
      if (!navItem) return null
      if (this.resolveDeclarationFn(navItem.hideSecondLevel)) return null
      return navItem.key
    },
  },

  watch: {
    projectId: {
      handler(projectId) {
        if (projectId != null) this.pinSecondLevel = true
      },
      immediate: true,
    },

    '$route.path': {
      handler(path) {
        this.focusedNav = null
        this.hoveredNav = null
        if (path && path.startsWith('/admin/')) this.pinSecondLevel = true
      },
      immediate: true,
    },

    menu: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(this.setupTooltipVisibility)
        setTimeout(this.setupTooltipVisibility, 0)
      },
    },

    dashboardFeatureActive: {
      immediate: true,
      handler() {
        this.$nextTick(this.setupTooltipVisibility)
        setTimeout(this.setupTooltipVisibility, 0)
      },
    },
  },

  mounted() {
    this.$store.dispatch('license/getInformation')
  },

  methods: {
    navItemVisible(navItem) {
      return navItem.showIf == null || !!navItem.showIf(this)
    },

    onNavItemBlur(event, prop = 'hoveredNav') {
      this[prop] = null
      setTimeout(() => {
        if (this.hoveredNav == null && this.focusedNav == null) {
          this.activeNav = this.pinnedNav
        } else {
          this.activeNav = this.hoveredNav || this.focusedNav
        }
      }, FADE_TIMEOUT)
    },

    resolveDeclarationFn(value) {
      return R.is(Function, value) ? value(this) : value
    },

    getChildBind({ to, exact }) {
      const bind = {}
      if (to) bind.to = this.resolveDeclarationFn(to)
      if (exact) bind.exact = !!this.resolveDeclarationFn(exact)
      return bind
    },

    getChildListeners(childItem, pinNav = false) {
      const { click, children, expanded, to } = childItem
      const listeners = {}
      if (children?.length) {
        listeners.click = (event) => {
          this.$set(childItem, 'expanded', !expanded)
          event.target.focus()
        }
      } else if (click) {
        listeners.click = (event) => {
          const rv = click(this)
          event.target.focus()
          return rv
        }
      } else if (to && pinNav) {
        listeners.click = () => {
          this.pinSecondLevel = true
        }
      }
      return listeners
    },

    filterChildren(items) {
      return (items || []).filter(item => item.showIf == null || item.showIf(this))
    },

    getNavItemActive(navItem) {
      return navItem.active != null && !!navItem.active(this)
    },

    setupTooltipVisibility() {
      this.navsWithTooltip = this.$refs.navItemLabels
        .filter(el => el.getBoundingClientRect().width >= 70)
        .map(el => el.dataset.navKey)
    },
  },
}
</script>

<style lang="sass">
@import '../scss/variables'

$transition-duration: 150ms
$transition-timing-fn: map-get($transition, 'swing')
$transition-delay: 60ms

.TheNavigationDrawer
  $self: &

  height: 100vh
  overflow: hidden
  position: fixed
  top: 0
  left: 0
  z-index: 6
  display: flex
  width: 70px

  &--expanded
    width: 260px

  &__brand-link
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 64px

  &__first-level
    flex: 0 0 70px
    height: 100vh
    position: relative
    z-index: 8
    user-select: none

  &__second-level-transition
    flex: 1 1 190px
    height: 100vh

    &-leave-active, &-enter-active
      transition: all $transition-duration $transition-timing-fn $transition-delay

    &-enter, &-leave-to
      transform: translateX(-100%)

  &__second-level
    // flex: 1 1 190px
    height: 100vh
    width: 190px
    position: absolute
    left: 70px
    top: 0
    z-index: 7
    border-right: 2px solid #E6E6F2
    background: #F5F5F9
    overflow: hidden auto
    user-select: none

    &--pinned
      z-index: 6

  &__heading
    overflow: hidden
    text-overflow: ellipsis
    padding: 13px 12px 0 20px
    height: 31px

    font-weight: 400
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    color: #A09EB9

  &__nav-item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 70px
    height: 76px
    overflow: hidden visible
    text-overflow: ellipsis
    color: white !important
    text-decoration: inherit
    cursor: pointer
    position: relative
    box-shadow: inset 4px 0 0 0 transparent, inset -4px 0 0 0 transparent
    transition: all $transition-duration $transition-timing-fn $transition-delay

    .v-icon
      color: #C1C1D2 !important

    &--active
      color: var(--v-primary-base) !important
      box-shadow: inset 4px 0 0 0 var(--v-primary-base), inset -4px 0 0 0 transparent

      .v-icon
        color: inherit !important

    &:hover, &:focus, &:active, &--active
      background: #35354C

  &__nav-icon
    color: inherit !important

  &__nav-label
    overflow: hidden
    white-space: nowrap
    max-width: 100%
    color: white
    text-overflow: ellipsis
    padding: 0 4px
    font-weight: 400
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    margin-top: 4px

  &__pin
    position: absolute
    width: 24px
    height: 24px
    top: 5px
    right: 12px
    display: flex
    align-items: center
    justify-content: center

  &__child-item
    padding-left: 20px
    padding-right: 12px

    font-weight: 500
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em

    &:hover, &:active, &:focus
      color: var(--v-primary-base) !important

    &--active
      color: var(--v-primary-base) !important

    &--sub
      padding-left: 38px
    // color: var(--v-primary-base) !important

    & + &
      margin-top: 8px

  &__expand-icon
    margin-left: auto

  &__child-item--expanded &__expand-icon
    transform: rotate(180deg)

  &__expansion-list
    background: #EDEEF5
    border-top: 2px solid #E6E6F2
    border-bottom: 2px solid #E6E6F2
    padding: 8px 0
    margin-top: 8px
</style>
